import { GetServerSideProps } from 'next';

import withShopContext from 'core/hocs/withShopContext';
import ErrorPage from '@templates/ErrorPage';
import handleRedirect from 'utils/handleRedirect';

export const getServerSideProps: GetServerSideProps = withShopContext(async ({ res }, { client, shopId }) => {
  if (res.statusCode === 404) {
    return handleRedirect(client, `${res.req.url}`, Number(shopId));
  }

  return {
    props: { errorCode: res.statusCode },
  };
});

export default ErrorPage;
